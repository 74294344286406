<script setup>
import { useWindowSize, breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import Snowing from '@/Components/Snowing.vue';
import { Link, usePage } from '@inertiajs/vue3';

import { useDark, useToggle } from '@vueuse/core'
import { ref, onMounted } from 'vue';
import moment from 'moment';

const page = usePage();

const isDark = useDark(false)
const breakpoints = useBreakpoints(breakpointsTailwind)
const { width, height } = useWindowSize()

const current = breakpoints.current()
const active = breakpoints.active()
const xs = breakpoints.smaller('sm')
const xse = breakpoints.smallerOrEqual('sm')
const sm = breakpoints.between('sm', 'md')
const md = breakpoints.between('md', 'lg')
const lg = breakpoints.between('lg', 'xl')
const xl = breakpoints.between('xl', '2xl')
const xxl = breakpoints['2xl']

const imageUrl = ref('https://cdn.fun.paris/imageMPP/paris-eiffel-riverseine.jpg')
if(page.props.todayis.isChristmasEve || page.props.todayis.isChristmas) {
    imageUrl.value = 'https://cdn.fun.paris/imageMPP/disneyland-park-town-square_christmas.jpg?height=1200'
}
if(page.props.todayis.isNewYear) {
    imageUrl.value = 'https://cdn.fun.paris/imageMPP/celebration-new-year-s-eve-paris-france.jpg?height=1200'
}
</script>
<template>
    <main class="min-h-screen relative dark:bg-primary-950 bg-white">
        <div class="grid sm:grid-cols-7 z-10">
            <div class="sm:col-span-3 p-5 2xl:p-10" :style="'background-image: url('+imageUrl+'); background-size: cover; background-position: top;'">
                <Snowing v-if="$page.props.todayis.isChristmasEve || $page.props.todayis.isChristmas || $page.props.todayis.isNewYear" />
                <Link :href="route('dashboard')"><img src="../../images/monpetiparis-logo.png" alt="MPP - Mon Petit Paris" class="w-2/4 mx-auto sm:w-1/4 sm:m-0" /></Link>
            </div>
            <div class="sm:col-span-4 sm:h-screen flex w-full items-center sm:shadow-md">
                <slot />

                <div v-if="$page.props.todayis.isChristmasEve || $page.props.todayis.isChristmas" class="hidden lg:inline fixed bottom-0 right-0">
                    <img class="hidden lg:inline xl:hidden" src="https://cdn.fun.paris/imageMPP/christmas_tree_decoration_2024.png?height=350&width=280" />
                    <img class="hidden xl:inline" src="https://cdn.fun.paris/imageMPP/christmas_tree_decoration_2024.png?height=450&width=350" />
                </div>
            </div>
        </div>
    </main>
    
    <div class="fixed bottom-5 left-2 bg-black text-white dark:bg-white dark:text-black px-2 py-1 rounded-md">
        <template v-if="$page.props.todayis.isChristmasEve || $page.props.todayis.isChristmas">
            &copy; Disney
        </template>
        <template v-else>
            <span v-if="xs" class="font-mono">XS {{ width }} X {{ height }}</span>
            <span v-if="sm" class="font-mono">SM {{ width }} X {{ height }}</span>
            <span v-if="md" class="font-mono">MD {{ width }} X {{ height }}</span>
            <span v-if="lg" class="font-mono">LG {{ width }} X {{ height }}</span>
            <span v-if="xl" class="font-mono">XL {{ width }} X {{ height }}</span>
            <span v-if="xxl" class="font-mono">XXL {{ width }} X {{ height }}</span>
        </template>
    </div>
</template>
